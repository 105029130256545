import React from "react"
import Topbar from '../components/topbar'
import Footer from '../components/footer'
import SEO from '../components/seo'
// import {
  // Button,
  // Col,
  // Row,
  // Tag,
// } from 'antd'
// import { CheckOutlined, SendOutlined, TeamOutlined } from '@ant-design/icons'

class HowItWorks extends React.Component {

  componentDidMount() {
    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'How it works',
        page: '/how-it-works'
      });
      window.cmAgent.dispatch();
    }

    if (window && window.gtag) {
      window.gtag('config', 'G-KTYYBQVLCK', {'page_path': '/how-it-works'});
    }
  }

  render() {

    return <div>
      <SEO title="How it works | Notifuse" />

      <Topbar />

      <section className="how">
        <div className="container">
          <h1>How It Works</h1>

          <h2>1. Create your notifications templates</h2>
          <p>Configure all the types of notifications you need to send, their channels & translations.</p>

          <img src="/images/notifuse-template.png" alt="Notifuse UI" style={{maxWidth: '100%'}} />

          <div className="divider margin-v-l"></div>

          <h2>2. Integrate the nofitication bell</h2>
          <h2>3. Send notifications with the API</h2>

          <div>Coming soon...</div>
        </div>
      </section>

      <Footer />
    </div>
  }
}

export default HowItWorks
